import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Social Media`}</h2>
    <ProjectCard title="Instagram" link="https://www.instagram.com/jcgaming.ir/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Follow us on Instagram
      <p>{`مارو در اینستا فالو کنید`}</p>
    </ProjectCard>
    <ProjectCard title="Instagram (LISOO)" link="https://www.instagram.com/claw_kamy/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Follow Kamy on Instagram
      <p>{`کامران را در اینستا فالو کنید`}</p>
    </ProjectCard>
    <ProjectCard title="YouTube" link="https://www.youtube.com/channel/UCgf2G9K5am1XNCZhmAlFWPQ" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  See our videos on YouTube
      <p>{`  فیلم‌های مارو در یوتیوب ببینید`}</p>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      