import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Free Fire ID (MENA Server)`}</h2>
    <blockquote>
      <p parentName="blockquote">{`JTAG: 3729659137`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`LISOO: 2855775680`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`RELAPSE: 839983440`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      